import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

import LoginPage from "../LoginPage/Loadable";
import SignupPage from "../SignupPage/Loadable";
import ForgotPasswordPage from "../ForgotPasswordPage/Loadable";
import CheckEmailPage from "../CheckEmailPage/Loadable";
import VerifyEmailPage from "../VerifyEmailPage/Loadable";
import AccountCompletePage from "../AccountCompletePage/Loadable";
import CreateNewPasswordPage from "../CreateNewPasswordPage/Loadable";
import PasswordResetSuccessPage from "../PasswordResetSuccessPage/Loadable";
import VerifyPage from "../VerifyPage/Loadable";
import ErrorPage from "../ErrorPage/Loadable";

import Layout from "../Layout";
import HealthCheckPage from "../HealthCheckPage";

const Wrapper = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default function Router() {
  return (
    <BrowserRouter>
      <Layout>
        <Wrapper>
          <Switch>
            <Route exact path="/error" component={ErrorPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/signup" component={SignupPage} />
            <Route exact path="/forgetPW" component={ForgotPasswordPage} />
            <Route exact path="/checkEmail" component={CheckEmailPage} />
            <Route exact path="/verifyEmail" component={VerifyEmailPage} />
            <Route exact path="/verifyEmailExpired">
              <VerifyEmailPage expired />
            </Route>
            <Route exact path="/accountComplete" component={AccountCompletePage} />
            <Route exact path="/resetSuccess" component={PasswordResetSuccessPage} />
            <Route exact path="/resetPW/:resetToken" component={CreateNewPasswordPage} />
            <Route exact path="/verify/:email/:token" component={VerifyPage} />
            <Route exact path="/healthz" component={HealthCheckPage} />
            <Redirect from="/" to="/error" />
          </Switch>
        </Wrapper>
      </Layout>
      <NotificationContainer />
    </BrowserRouter>
  );
}
