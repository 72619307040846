import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import throttle from "lodash/throttle";
import configureStore from "./configureStore";
import LanguageProvider from "./containers/LanguageProvider";
import Router from "./containers/Router";
import { saveState, loadState } from "./localStorage";
import client from "./apolloClient";
import messages_en from "./translations/en.json";
import messages_zh_hans from "./translations/zh-Hans.json";
import messages_zh_hant from "./translations/zh-Hant.json";
import { CookiesProvider } from "react-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "react-notifications/lib/notifications.css";
import { GlobalStyles, ThemeProvider } from "@mui/material";
import defaultTheme from "../src/containers/styles/theme";

// Create redux store with h‰istory
const initialState = loadState();
const store = configureStore(initialState);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);

const translationMessages = {
  en: messages_en,
  "zh-Hans": messages_zh_hans,
  "zh-Hant": messages_zh_hant,
};

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <CookiesProvider>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <ThemeProvider theme={defaultTheme}>
              <GlobalStyles
                styles={{
                  body: { background: "#e5e5e5", height: "100vh" },
                  "#root": {
                    height: "100%",
                  },
                }}
              />
              <LanguageProvider messages={translationMessages}>
                <Router />
              </LanguageProvider>
            </ThemeProvider>
          </Provider>
        </ApolloProvider>
      </CookiesProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
