/**
 * Create the store with dynamic reducers
 */

import { createStore } from "redux";
import createReducer from "./reducers";

export default function configureStore(initialState = {}) {
  // let composeEnhancers = compose;
  // // const reduxSagaMonitorOptions = {};

  // // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  // /* istanbul ignore next */
  // if (process.env.NODE_ENV !== "production" && typeof window === "object") {
  //   /* eslint-disable no-underscore-dangle */
  //   if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  //     composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

  //   // NOTE: Uncomment the code below to restore support for Redux Saga
  //   // Dev Tools once it supports redux-saga version 1.x.x
  //   // if (window.__SAGA_MONITOR_EXTENSION__)
  //   //   reduxSagaMonitorOptions = {
  //   //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
  //   //   };
  //   /* eslint-enable */
  // }

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state

  const store = createStore(
    createReducer(),
    initialState,
  );

  // Extensions
  store.injectedReducers = {}; // Reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
