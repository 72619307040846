export const getLocale = () => {
  const languageParts = navigator.language.split("-");
  const language = languageParts[0];
  const locale = languageParts[1];
  if (language === "zh") {
    if (!locale || (locale && ["hk", "tw"].includes(locale))) {
      return "zh-Hant";
    }
    return "zh-Hans";
  }
  return "en";
};
