export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("youcodia2-auth");
    if (serializedState === null) {
      return undefined;
    }
    const json = JSON.parse(serializedState);
    delete json.route;
    return json;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const s = {
      app: state.app,
      language: state.language,
    };
    const serializedState = JSON.stringify(s);
    localStorage.setItem("youcodia2-auth", serializedState);
    return serializedState;
  } catch (err) {
    return undefined;
  }
};

export const resetState = () => {
  try {
    localStorage.clear();
    return {};
  } catch (err) {
    return undefined;
  }
};
