/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { IntlProvider } from "react-intl";
import { selectLocale, changeLocale } from "@youcodia/base.reducers.language-reducer";
import { useEffect } from "react";

export function LanguageProvider({ messages, children }) {
  const storedLocale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const query = window.location.search;
  const urlWithFrontParams = new URLSearchParams(query);
  let paramsLocale = urlWithFrontParams.get("frontlocale");

  useEffect(() => {
    if (paramsLocale) {
      dispatch(changeLocale(paramsLocale));
    }
  }, [paramsLocale, dispatch]);

  const transformLocale = () => {
    const localeSplits = storedLocale.toLowerCase().split("_");
    if (localeSplits.length > 1) {
      return `${localeSplits[0]}-${localeSplits[1].charAt(0).toUpperCase()}${localeSplits[1].slice(1)}`;
    }
    return storedLocale.toLowerCase();
  };
  const locale = transformLocale();

  return (
    <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
