import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    primary: {
      main: "#F56C20",
    },
  },
  typography: {
    h3: {
      color: "#FFFFFF",
    },
    h5: {
      color: "#494949",
    },
    caption: {
      color: "#FFFFFF",
    },
    body1: {
      color: "#494949",
    },
    bodyLight: {
      color: "#838383",
    },
    body2: {
      color: "#838383",
    },
  },
});
