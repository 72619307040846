export const getBackendHost = () => {
  // return 'https://ctfeauction.tsunadon.com/graphql';
  switch (process.env.NODE_ENV) {
    case "development":
      return "http://localhost:4000";
    case "production":
      if (process.env.REACT_APP_ENV === "staging") {
        return "https://login.staging.youcodia.life/api";
      } else {
        return "https://login.youcodia.life/api";
      }
    default:
      return "http://localhost:5000";
  }
};
